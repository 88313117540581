<template>
  <div class="measure-audition">
    <div
      id="measure-audition-header"
      class="measure-audition-header form-header"
    >
      <div>
        <a
          v-if="showGoBack"
          href="javascript:void(0)"
          class="back"
          @click="goBack"
        >
          {{ $t("back") }}
        </a>
      </div>
      <span
        v-if="loaded"
        class="measure_name"
      >{{ $t('auditionMeasure') }}: MEASURE - {{ details.measure_storage ? details.measure_storage.abbreviation : '' }}</span>
      <span class="exit-container">
        <router-link
          class="exit"
          :to="{name: 'MeasuresClinician', query: { 'detailId': details.id }}"
        >
          {{ $t("exit") }}
        </router-link>
      </span>
    </div>
    <router-view v-if="loaded" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AuditionMeasure',
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      details: 'measureLibraryDetail',
      auditionMeasure: 'activeMeasure',
      enhanceAuditionVariables: 'getEnhanceAuditionVariables'
    }),
    showGoBack () {
      return this.auditionMeasure && this.auditionMeasure.page && this.auditionMeasure.page.id !== 0 && this.$route.name !== 'AuditionMeasureReport'
    }
  },
  created () {
    this.$store.commit('measures/SET_AUDITION_MODE', true)
    const auditionEnhanceVariables = this.enhanceAuditionVariables ? this.enhanceAuditionVariables : {}
    const auditionStoredData = JSON.parse(localStorage.getItem('auditionData')) || {}
    const auditionData = { ...auditionEnhanceVariables, ...auditionStoredData }

    // While first creating the element if there is something at the localStorage load from there (prevent f5 issues).
    if (auditionData) {
      this.$store.commit('measures/SAVE_AUDITION_PAGE', auditionData)
    }

    // Do normal
    this.loaded = false
    this.fetchInformation().then(() => {
      this.loaded = true
    })
    return true
  },
  beforeDestroy () {
    this.$store.commit('measures/RESET_ACTIVE_MEASURE')
    this.$store.commit('measures/SAVE_AUDITION_PAGE', null)
    this.$store.commit('measures/RESET_AUDITION_REPORT')
    this.$store.commit('measures/SET_AUDITION_MODE', false)
  },
  methods: {
    goBack () {
      if (this.$route.name === 'AuditionMeasureReport') {
        this.$router.push({ name: 'AuditionMeasureQuestionnaire' })
        return true
      }

      this.$root.$emit('goPrevPage')
      return true
    },
    getMeasureDetail (id) {
      this.$store.dispatch('GET_MEASURE_LIBRARY_DETAIL', id).catch((e) => {
        this.$handleApiError(e)
      })
    },
    fetchInformation () {
      const measureId = this.$route.params.measureId
      return new Promise((resolve) => {
        if (this.details.id && this.details.id === measureId) {
          resolve(true)
          return true
        }

        const promises = []
        promises.push(this.getMeasureDetail(measureId))
        Promise.all(promises).then(() => {
          resolve(true)
        })
      })
    }

  }
}
</script>
<style lang="scss" scoped>
@import './../../../assets/styles/override.scss';
.measure-audition {
  height: 100%;
  background-color: $light-blue;
  &-header {
    height: 57px;
    display: grid;
    display: -ms-grid;
    flex-direction: column;
    -ms-grid-columns: 90px 1fr 90px;
    grid-template-columns: 90px 1fr 90px;
    background-color: $white;
    position: fixed;
    width: 100%;
    z-index: 1;
    > * {
      display: flex;
      -ms-grid-row: 1;
      grid-row: 1;
      align-items: center;
      height: 57px;
    }
    .back {
      padding-left: 19px;
      -ms-grid-column: 1;
      grid-column: 1;
    }
    .measure_name {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: $navy-blue;
      justify-content: center;
      -ms-grid-column: 2;
      grid-column: 2;
    }
    .exit-container {
      -ms-grid-column: 3;
      grid-column: 3;
      .exit {
        text-transform: capitalize;
        padding-right: 54px;
      }
    }
  }
}
</style>
